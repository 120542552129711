<template>
  <div class="about-us">
    <div class="head">
      <h2 class="about">
        关于可牛软件
      </h2>
      <p class="about-text">
        让工作更简单高效
      </p>
    </div>
    <div class="company-profile">
      <h2 class="synopsis-title">
        公司简介
      </h2>
      <p class="synopsis">
        可牛软件团队是致力于PC及移动办公软件开发的专业团队，团队成员深耕办公领域，拥有二十多年的软件开发经验，我们秉承用户至上的精神，开发优质轻便的办公产品，只为给用户提供更高效的办公体验。
      </p>
      <ul>
        <li
          v-for="i in synopsisList"
          :key="i.id"
        >
          <div
            class="company-profile-icon"
            :class="i.iconName"
          />
          <h3>{{ i.title }}</h3>
          <p>{{ i.text }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  data () {
    return {
      synopsisList: [
        {
          id: 1,
          iconName: 'working-hours-icon',
          title: '9:00-18:00',
          text: '工作时间',
        },
        {
          id: 2,
          iconName: 'email-icon',
          title: 'keniu_contact@conew.com',
          text: '邮箱',
        },
        {
          id: 3,
          iconName: 'telephone-icon',
          title: '0756-3327084',
          text: '联系电话',
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.about-us {
  height: 990px;

  .head {
    background: url("./_images/about-us-bg.png") no-repeat;
    background-size:cover;
    height: 460px;
    color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 80px;
    .about {
        font-size: 2.6875rem;
        color: #fff;
        margin-bottom: 16px;
    }
    .about-text {
      color: #ffffff;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2.0625rem;
      letter-spacing: .125rem;
    }
  }
  .company-profile {
    max-width: 1000px;
    margin: auto;
    .synopsis-title {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 8px;
    }
    .synopsis {
      line-height: 30px;
      font-size: 20px;
      color: #666666;
    }
    ul {
      display: flex;
      justify-content: space-between;
      margin-top: 80px;
      text-align: center;
      li {
        .company-profile-icon {
          background: red;
          width: 60px;
          height: 60px;
          margin: auto;
          &.working-hours-icon {
            background: url("./_images/working-hours-icon.png") no-repeat;
          }
          &.email-icon {
            background: url("./_images/email-icon.png") no-repeat;
          }
          &.telephone-icon {
            background: url("./_images/telephone-icon.png") no-repeat;
          }
          &.company-profile-icon {
            background-size: 100%;
          }
        }
        h3 {
          font-size: 28px;
          color: #FF5627;
          margin-top: 20px;
          margin-bottom: 10px;
        }
        p {
          font-size: 20px;
          color: #1c1c1c;
        }
      }
    }
  }
}
</style>
